import {twMerge} from 'tailwind-merge'
import {forwardRef, Ref, useEffect, useState} from 'react'
import {Typography} from '../../Display'
import GIcon from '../../Libs/Icon/GIcon'

export interface EmailFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  helperText?: string
  className?: string
  error?: boolean
  placeholder?: string
  required?: boolean
  classes?: {
    container?: string
    wrapper?: string
    input?: string
    chip?: string
  }
  onAddEmail?: (emails: string[]) => void
  defaultValue?: string[] // Accept default values
  rule?: {
    required?: boolean | string // Rule for validation
  }
  emailType?: 'bcaEmail' | 'normal' // Email type property
}

function _EmailInput(
  {
    label,
    helperText,
    className,
    error,
    placeholder,
    required,
    classes,
    onAddEmail,
    defaultValue = [],
    rule = {}, // Add rule for validation
    emailType = 'normal', // Default to "normal"
    ...props
  }: EmailFieldProps,
  forwardedRef: Ref<HTMLInputElement>
) {
  const [inputValue, setInputValue] = useState('')
  const [emailList, setEmailList] = useState<string[]>(defaultValue)
  const [validationError, setValidationError] = useState<string>('')
  const [validationErrorState, setValidationErrorState] = useState<boolean>(false)

  useEffect(() => {
    if (defaultValue.length > 0) {
      setEmailList(defaultValue)
    }
  }, [defaultValue])

  const isValidEmail = (email: string) => {
    const lowercasedEmail = email.toLowerCase();

    if (emailType === 'bcaEmail') {
      const bcaEmailRegex = /^[^\s@]+@bca\.id$/ // Regex for "bcaEmail"
      return bcaEmailRegex.test(lowercasedEmail)
    } else {
      const normalEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/ // Generic email regex
      return normalEmailRegex.test(lowercasedEmail)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      e.preventDefault()

      if (!isValidEmail(inputValue.trim())) {
        setValidationErrorState(true)
        setValidationError('Wrong email format (user@bca.id)')
        return
      }

      if (emailList.length >= 3) {
        setValidationErrorState(true)
        setValidationError('You can only add a maximum of 3 emails.')
        return
      }

      const normalizedEmailList = emailList.map(email => email.toLowerCase());
      if (normalizedEmailList.includes(inputValue.trim().toLowerCase())) {
        setValidationErrorState(true)
        setValidationError('Email already exists in the list.')
        return
      }

      setValidationError('')
      setValidationErrorState(false)
      const updatedList = [...emailList, inputValue.trim()]
      setEmailList(updatedList)
      setInputValue('')
      onAddEmail?.(updatedList)
    }
  }

  const handleDeleteEmail = (index: number) => {
    const updatedList = emailList.filter((_, i) => i !== index)
    setEmailList(updatedList)
    onAddEmail?.(updatedList)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)

    if (validationError) {
      setValidationError('')
      setValidationErrorState(false)
    }
  }

  const validateRequired = () => {
    if (rule?.required && emailList.length === 0) {
      setValidationError(
        typeof rule.required === 'string' ? rule.required : 'Email Marketing Representative cannot be empty'
      )
    }
  }

  return (
    <div className={twMerge('w-full', classes?.container, className)}>
      {!!label && (
        <Typography className={twMerge('block mb-1 font-medium text-gray-700')}>
          {label} {required && <span className='text-danger-500'>*</span>}
        </Typography>
      )}
      <div
        className={twMerge(
          'flex items-center border rounded-lg pl-4 gap-1',
          error || validationError ? 'border-red-500' : 'border-gray-300',
          classes?.wrapper
        )}
      >
        <GIcon icon='IconEnvelope' />
        <input
          type='text'
          ref={forwardedRef}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder || 'Enter email address (maximum of 3)'}
          onBlur={validateRequired} // Trigger validation on blur
          className={twMerge(
            'flex-1 border-none outline-none bg-transparent placeholder-gray-400',
            classes?.input
          )}
          {...props}
        />
      </div>

      <div className='flex flex-wrap gap-2 mt-2 text-xs font-semibold'>
        {emailList.map((email, index) => (
          <div
            key={index}
            className={twMerge(
              'flex items-center bg-blue-100 text-primary-600 rounded-full px-3 py-1',
              classes?.chip
            )}
          >
            <span className='mr-2'>{email}</span>
            <button
              type='button'
              onClick={() => handleDeleteEmail(index)}
              className='text-primary-600 hover:text-danger-500'
            >
              x
            </button>
          </div>
        ))}
      </div>

      {error || validationErrorState ? (
        <Typography className='text-xs text-danger-500'>
          {validationError ? validationError : `Email Marketing Representative cannot be empty`}
        </Typography>
      ) : (
        !!helperText && <Typography className='mt-1 text-xs text-gray-500'>{helperText}</Typography>
      )}
    </div>
  )
}

export const EmailInputField = forwardRef(_EmailInput)
