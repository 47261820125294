import {
  twMerge
} from 'tailwind-merge'
import {
  Ref,
  forwardRef
} from "react";

import {
  TextareaProps
} from "./Textarea.types";

import {
  HelperText,
  Label
} from "../../Display";
import {
  Theme
} from './Textarea.theme';

function _Textarea(
  {
    helperText,
    className,
    classes,
    error,
    label,
    rule,
    ...props
  }: TextareaProps,
  forwardedRef: Ref<HTMLTextAreaElement>
) {

  console.log(error);
  
  return (
    <div className={twMerge(Theme.container, classes?.container)}>
      {(!!label) && <Label required={props.required} {...{ error }}>{label}</Label>}
      <textarea
        rows={4}
        ref={forwardedRef}
        className={twMerge(
          Theme.input,
          classes?.input,
          error && Theme.inputError,
          className
        )}
        {...props}
      />
      {(!!helperText) && <HelperText {...{ error }}>{helperText}</HelperText>}
    </div>
  );
}

export const Textarea = forwardRef(_Textarea);
