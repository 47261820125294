const InquiryManagementScreens = {
  INQUIRY_MANAGEMENT: {
    PATH: '/inquiry-management',
    TITLE: 'Inquiry Management',
    TITLE_ALT: 'Inquiry Management',
  },
  LIST_NEED_PROCESS_POLICY_RENEWAL: {
    PATH: '/inquiry-management/policy-renewal/need-process',
    HEADER_NAME: 'List Renewal Inquiries',
    TITLE: 'Policy Renewal',
    TITLE_ALT: 'Policy Renewal',
    // PERMISSION: 'policy_renewal.read',
  },
  LIST_DONE_PROCESS_POLICY_RENEWAL: {
    PATH: '/inquiry-management/policy-renewal/done-process',
    HEADER_NAME: 'List Renewal Inquiries',
    TITLE: 'Policy Renewal',
    TITLE_ALT: 'Policy Renewal',
    // PERMISSION: 'policy_renewal.read',
  },
  DETAIL_POLICY_RENEWAL: {
    PATH: '/inquiry-management/policy-renewal/detail/:id',
    TITLE: 'Inquiry Detail',
    TITLE_ALT: 'Inquiry Detail',
    // PERMISSION: 'policy_renewal.read',
  },
  LIST_NEED_PROCESS_POLICY_ENDORSEMENT: {
    PATH: '/inquiry-management/policy-endorsement/need-process',
    HEADER_NAME: 'List Endorsement Inquiries',
    TITLE: 'Policy Endorsement',
    TITLE_ALT: 'Policy Endorsement',
    // PERMISSION: 'policy_endorsement.read',
  },
  LIST_DONE_PROCESS_POLICY_ENDORSEMENT: {
    PATH: '/inquiry-management/policy-endorsement/done-process',
    HEADER_NAME: 'List Endorsement Inquiries',
    TITLE: 'Policy Endorsement',
    TITLE_ALT: 'Policy Endorsement',
    // PERMISSION: 'policy_endorsement.read',
  },
  DETAIL_POLICY_ENDORSEMENT: {
    PATH: '/inquiry-management/policy-endorsement/detail/:id',
    TITLE: 'Inquiry Detail',
    TITLE_ALT: 'Inquiry Detail',
    // PERMISSION: 'policy_endorsement.read',
  },
  LIST_NEED_PROCESS_PRODUCT_INFO: {
    PATH: '/inquiry-management/product-info/need-process',
    HEADER_NAME: 'List Product Info Inquiries',
    TITLE: 'Product Info',
    TITLE_ALT: 'Product Info',
    // PERMISSION: 'product_info.read',
  },
  LIST_DONE_PROCESS_PRODUCT_INFO: {
    PATH: '/inquiry-management/product-info/done-process',
    HEADER_NAME: 'List Product Info Inquiries',
    TITLE: 'Product Info',
    TITLE_ALT: 'Product Info',
    // PERMISSION: 'product_info.read',
  },
  DETAIL_PRODUCT_INFO: {
    PATH: '/inquiry-management/product-info/detail/:id',
    TITLE: 'Inquiry Detail',
    TITLE_ALT: 'Inquiry Detail',
    // PERMISSION: 'product_info.read',
  },

}

export default InquiryManagementScreens
