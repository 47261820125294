const GeneralConfigScreens = {
  GENERAL_CONFIG: {
    PATH: '/general-config',
    TITLE: 'General Config',
    TITLE_ALT: 'General Config',
  },
  VIEW_TERMS_CONDITIONS: {
    PATH: '/general-config/terms-conditions',
    HEADER_NAME: 'Terms & Conditions',
    TITLE: 'Terms & Conditions',
    TITLE_ALT: 'List Terms & Conditions',
    PERMISSION: 'manage_terms_&_condition.read'
  },
  ADD_TERMS_CONDITIONS: {
    PATH: '/general-config/terms-conditions/add',
    HEADER_NAME: 'Create Terms & Conditions',
    TITLE: 'Create Terms & Conditions',
    TITLE_ALT: 'Create Terms & Conditions',
    PERMISSION: 'manage_terms_&_condition.create'
  },
  EDIT_TERMS_CONDITIONS: {
    PATH: '/general-config/terms-conditions/edit/:id',
    TITLE: 'Edit Terms & Conditions',
    TITLE_ALT: 'Edit Terms & Conditions',
    PERMISSION: 'manage_terms_&_condition.update'
  },
  DETAIL_TERMS_CONDITIONS: {
    PATH: '/general-config/terms-conditions/detail/:id',
    TITLE: 'Detail Terms & Conditions',
    TITLE_ALT: 'Detail Terms & Conditions',
    PERMISSION: 'manage_terms_&_condition.update'
  },
  VIEW_PRIVACY_POLICY: {
    PATH: '/general-config/privacy-policy',
    HEADER_NAME: 'Privacy Policy',
    TITLE: 'Privacy Policy',
    TITLE_ALT: 'Privacy Policy',
    PERMISSION: 'manage_privacy_policy.read'
  },
  ADD_PRIVACY_POLICY: {
    PATH: '/general-config/privacy-policy/add',
    HEADER_NAME: 'Create Privacy Policy',
    TITLE: 'Create Privacy Policy',
    TITLE_ALT: 'Create Privacy Policy',
    PERMISSION: 'manage_privacy_policy.create'
  },
  EDIT_PRIVACY_POLICY: {
    PATH: '/general-config/privacy-policy/edit',
    TITLE: 'Edit Privacy Policy',
    TITLE_ALT: 'Edit Privacy Policy',
    PERMISSION: 'manage_privacy_policy.update'
  },
  LIST_FAQ: {
    PATH: '/general-config/faq',
    HEADER_NAME: 'FAQ',
    TITLE: 'List FAQ',
    TITLE_ALT: 'FAQ',
    PERMISSION: 'manage_faq.read'
  },
  ADD_FAQ: {
    PATH: '/general-config/faq/add',
    HEADER_NAME: 'Create FAQ',
    TITLE: 'Create FAQ',
    TITLE_ALT: 'Create FAQ',
    PERMISSION: 'manage_faq.create'
  },
  DETAIL_FAQ: {
    PATH: '/general-config/faq/detail/:id',
    HEADER_NAME: 'Detail FAQ',
    TITLE: 'Detail FAQ',
    TITLE_ALT: 'Detail FAQ',
    PERMISSION: 'manage_faq.read'
  },
  EDIT_FAQ: {
    PATH: '/general-config/faq/edit/:id',
    HEADER_NAME: 'Edit FAQ',
    TITLE: 'Edit FAQ',
    TITLE_ALT: 'Edit FAQ',
    PERMISSION: 'manage_faq.update'
  },
}

export default GeneralConfigScreens
