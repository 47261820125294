import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
import Labels from 'src/app/constants/labels'

import { emailRegExp, formatMoney } from 'src/app/utils/input-utils'

export function cleanNumber(value?: string) {
  if (!!value) {
    const cleanNumber = value?.toString()?.replace(/[^\d]/g, '')
    return isNaN(+cleanNumber) ? undefined : +cleanNumber
  }
  return undefined
}

export function formatCurrency(inputEl?: HTMLInputElement | null) {
  if (!!inputEl?.value) {
    const _cleanNumber = cleanNumber(inputEl.value)
    if (_cleanNumber !== undefined) {
      inputEl.value = formatMoney(_cleanNumber)
    }
  }
}

export function getHelperTextMessage(
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>,
  props?: {helperText?: string}
) {
  if (!error) {
    return props?.helperText
  }
  const errorMessage = error?.message?.toString()
  const errorType = error?.type
  if (errorType === 'border') {
    return undefined
  } else {
    if (!errorMessage) {
      return Labels.FIELD_REQUIRED
    }
  }
  return errorMessage
}

export function getInputType(type?: string) {
  switch (type) {
    case 'currency':
      return 'text'

    case 'phone':
      return 'number'
  }
  return type ?? 'text'
}

export function validateForm(type?: string, required?: any) {
  return (value: any) => {
    if (!required && !value) {
      return undefined
    }
    if (type === 'email') {
      const isValid = emailRegExp.test(value)
      return isValid || 'Invalid email address. Please check & try again'
    }
    if (type === 'phone') {
      const isValid = +value > 0
      return isValid || 'Minimum character is 1'
    }
    return undefined
  }
}
