const LocationScreens = {
  LOCATION: {
    PATH: '/location',
    TITLE: 'Location',
    TITLE_ALT: 'Location',
  },
  LIST_WORKSHOP: {
    PATH: '/location/workshop',
    HEADER_NAME: 'Workshop',
    TITLE: 'Workshop',
    TITLE_ALT: 'List Workshop',
    PERMISSION: 'workshop.read'
  },
  EDIT_WORKSHOP: {
    PATH: '/location/workshop/edit/:id',
    TITLE: 'Edit Workshop',
    TITLE_ALT: 'Edit Workshop',
    PERMISSION: 'workshop.update'
  },
  DETAIL_WORKSHOP: {
    PATH: '/location/workshop/detail/:id',
    TITLE: 'Detail Workshop',
    TITLE_ALT: 'Detail Workshop',
    PERMISSION: 'workshop.read'
  },
  LIST_BRANCHES: {
    PATH: '/location/branches',
    HEADER_NAME: 'Branches',
    TITLE: 'Branches',
    TITLE_ALT: 'List Branches',
    PERMISSION: 'branches.read'
  },
  EDIT_BRANCHES: {
    PATH: '/location/branches/edit/:id',
    TITLE: 'Edit Branches',
    TITLE_ALT: 'Edit Branches',
    PERMISSION: 'branches.update'
  },
  DETAIL_BRANCHES: {
    PATH: '/location/branches/detail/:id',
    TITLE: 'Detail Branches',
    TITLE_ALT: 'Detail Branches',
    PERMISSION: 'branches.read'
  },
}

export default LocationScreens
