const AuditTrailScreens = {
    AUDIT_TRAIL: {
      PATH: '/audit-trail',
      TITLE: 'Audit Trail',
      TITLE_ALT: 'Audit Trail',
    },
    LIST_USER_ACTIVITY: {
      PATH: '/audit-trail/user-activity',
      HEADER_NAME: 'List User Activity',
      TITLE: 'User Activity',
      TITLE_ALT: 'User Activity',
      // PERMISSION: 'user_activity.read',
    },
  }
  
  export default AuditTrailScreens
  