const LoyaltyScreens = {
  LOYALTY: {
    PATH: '/loyalty',
    TITLE: 'Loyalty',
    TITLE_ALT: 'Loyalty',
  },
  LIST_LOYALTY_REWARD: {
    PATH: '/loyalty/reward',
    HEADER_NAME: 'Reward',
    TITLE: 'Reward',
    TITLE_ALT: 'List Reward',
    PERMISSION: 'workshop.read',
  },
  ADD_LOYALTY_REWARD: {
    PATH: '/loyalty/reward/add',
    HEADER_NAME: 'Reward',
    TITLE: 'Create Reward',
    TITLE_ALT: 'Create Reward',
    PERMISSION: 'workshop.read',
  },
  EDIT_LOYALTY_REWARD: {
    PATH: '/loyalty/reward/edit/:id',
    HEADER_NAME: 'Reward',
    TITLE: 'Edit Reward',
    TITLE_ALT: 'Edit Reward',
    PERMISSION: 'workshop.read',
  },
  DETAIL_LOYALTY_REWARD: {
    PATH: '/loyalty/reward/detail/:id',
    HEADER_NAME: 'Reward',
    TITLE: 'Reward Detail',
    TITLE_ALT: 'Reward Detail',
    PERMISSION: 'workshop.read',
  },
  VIEW_VOUCHER_LOYALTY_REWARD: {
    PATH: '/loyalty/reward/voucher/:id',
    HEADER_NAME: 'Reward',
    TITLE: 'Voucher List',
    TITLE_ALT: 'Voucher List',
    PERMISSION: 'workshop.read',
  },
  LIST_POINT_MATRIX: {
    PATH: '/loyalty/point-matrix',
    HEADER_NAME: 'Point Matrix',
    TITLE: 'Point Matrix',
    TITLE_ALT: 'List Point Matrix',
    PERMISSION: 'workshop.read',
  },
  ADD_POINT_MATRIX: {
    PATH: '/loyalty/point-matrix/add',
    HEADER_NAME: 'Point Matrix',
    TITLE: 'Create Point Matrix',
    TITLE_ALT: 'Create Point Matrix',
    PERMISSION: 'workshop.read',
  },
  EDIT_POINT_MATRIX: {
    PATH: '/loyalty/point-matrix/edit/:id',
    HEADER_NAME: 'Point Matrix',
    TITLE: 'Edit Point Matrix',
    TITLE_ALT: 'Edit Point Matrix',
    PERMISSION: 'workshop.read',
  },
  DETAIL_POINT_MATRIX: {
    PATH: '/loyalty/point-matrix/detail/:id',
    HEADER_NAME: 'Point Matrix',
    TITLE: 'Detail Point Matrix',
    TITLE_ALT: 'Detail Point Matrix',
    PERMISSION: 'workshop.read',
  },
  LIST_LOYALTY_MISSION: {
    PATH: '/loyalty/mission',
    HEADER_NAME: 'Mission',
    TITLE: 'Mission',
    TITLE_ALT: 'List Mission',
    // PERMISSION: 'mission.read'
  },
  DETAIL_LOYALTY_MISSION: {
    PATH: '/loyalty/mission/detail/:id',
    HEADER_NAME: 'Mission Detail',
    TITLE: 'Mission Detail',
    TITLE_ALT: 'Mission Detail',
    // PERMISSION: 'mission.read'
  },
  ADD_LOYALTY_MISSION: {
    PATH: '/loyalty/mission/add',
    HEADER_NAME: 'Create Mission',
    TITLE: 'Create Mission',
    TITLE_ALT: 'Create Mission',
    // PERMISSION: 'mission.create'
  },
  EDIT_LOYALTY_MISSION: {
    PATH: '/loyalty/mission/edit/:id',
    HEADER_NAME: 'Mission',
    TITLE: 'Edit Mission',
    TITLE_ALT: 'Edit Mission',
    // PERMISSION: 'mission.update'
  },
  ADD_MISSION_CONTENT: {
    PATH: '/loyalty/mission/add/:contentId/:step',
    HEADER_NAME: 'Create Mission',
    TITLE: 'Create Mission',
    TITLE_ALT: 'Create Mission',
    // PERMISSION: 'mission.create'
  },
  EDIT_MISSION_CONTENT: {
    PATH: '/loyalty/mission/edit/:id/:contentId/:step',
    HEADER_NAME: 'Edit Mission',
    TITLE: 'Edit Mission',
    TITLE_ALT: 'Edit Mission',
    // PERMISSION: 'mission.update'
  },
  LIST_LOYALTY_CAMPAIGN: {
    PATH: '/loyalty/campaign',
    HEADER_NAME: 'Active Campaign',
    TITLE: 'Campaign',
    TITLE_ALT: 'Active Campaign',
    // PERMISSION: 'campaign.read'
  },
  ADD_LOYALTY_CAMPAIGN: {
    PATH: '/loyalty/campaign/add',
    HEADER_NAME: 'Create Campaign',
    TITLE: 'Create Campaign',
    TITLE_ALT: 'Create Campaign',
    // PERMISSION: 'campaign.create'
  },
  EDIT_LOYALTY_CAMPAIGN: {
    PATH: '/loyalty/campaign/edit/:id',
    HEADER_NAME: 'Campaign',
    TITLE: 'Edit Campaign',
    TITLE_ALT: 'Edit Campaign',
    // PERMISSION: 'campaign.update'
  },
  LIST_LOYALTY_CAMPAIGN_HISTORY: {
    PATH: '/loyalty/campaign/history',
    HEADER_NAME: 'History Campaign',
    TITLE: 'Campaign',
    TITLE_ALT: 'Campaign',
    // PERMISSION: 'campaign.read'
  },
  DETAIL_LOYALTY_CAMPAIGN_HISTORY: {
    PATH: '/loyalty/campaign/history/detail/:id',
    HEADER_NAME: 'Campaign Detail',
    TITLE: 'Detail',
    TITLE_ALT: 'Detail',
    // PERMISSION: 'campaign.read'
  },
}

export default LoyaltyScreens
