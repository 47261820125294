import {Controller} from 'react-hook-form'

import {useEffect, useRef} from 'react'
import Labels from 'src/app/constants/labels'
import {FileUpload} from '../../Inputs'
import {InputFileUploadProps} from './InputFileUpload.types'

export function InputFileUpload({
  form,
  rule,
  name,
  onChangeFile,
  onClickAdditionalButton,
  ...props
}: InputFileUploadProps) {
  const fileUploadRef = useRef<HTMLDivElement | null>()

  useEffect(() => {
    if (form.formState.isSubmitting) {
      const errorKeys = Object.keys(form.formState.errors)
      if (errorKeys.indexOf(name) === 0) {
        fileUploadRef.current?.scrollIntoView()
      }
    }
  }, [form.formState.isSubmitting])

  return (
    <Controller
      control={form.control}
      rules={rule}
      name={name}
      render={({field, fieldState}) => {
        const isError = !!fieldState.error
        const errorMessage =
          rule?.required === true ? Labels.FIELD_REQUIRED : fieldState.error?.message?.toString()
        return (
          <>
            <FileUpload
              {...props}
              onChangeFile={(_) => {
                field.onChange(_)
                onChangeFile?.(_)
              }}
              onClickAdditionalButton={onClickAdditionalButton}
              helperText={isError ? errorMessage : props.helperText}
              required={!!rule?.required}
              value={field.value}
              error={isError}
              type={props.type}
              additionalButton={props.additionalButton}
              additionalButtonTitle={props.additionalButtonTitle}
              ref={(ref) => {
                fileUploadRef.current = ref
                field.ref(ref)
              }}
            />
          </>
        )
      }}
    />
  )
}
