import {SVGProps} from 'react'

export function ExportXlsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity='0.4'
        d='M18.8089 9.021C18.3574 9.021 17.7594 9.011 17.0149 9.011C15.199 9.011 13.7059 7.508 13.7059 5.675V2.459C13.7059 2.206 13.503 2 13.2525 2H7.96436C5.49604 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59109 22 8.1703 22H16.0455C18.5059 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.298 9.012 20.0465 9.013C19.6238 9.016 19.1168 9.021 18.8089 9.021Z'
        fill='white'
      />
      <path
        opacity='0.4'
        d='M16.0842 2.56737C15.7852 2.25637 15.2632 2.47037 15.2632 2.90137V5.53837C15.2632 6.64437 16.1732 7.55437 17.2792 7.55437C17.9772 7.56237 18.9452 7.56437 19.7672 7.56237C20.1882 7.56137 20.4022 7.05837 20.1102 6.75437C19.0552 5.65737 17.1662 3.69137 16.0842 2.56737Z'
        fill='white'
      />
      <path
        d='M15.1052 12.7093C14.8132 12.4203 14.3432 12.4183 14.0512 12.7113L12.4622 14.3083V9.48133C12.4622 9.07033 12.1282 8.73633 11.7172 8.73633C11.3062 8.73633 10.9722 9.07033 10.9722 9.48133V14.3083L9.38224 12.7113C9.09124 12.4183 8.62024 12.4203 8.32924 12.7093C8.03724 12.9993 8.03724 13.4703 8.32624 13.7623L11.1892 16.6383H11.1902C11.2582 16.7063 11.3392 16.7613 11.4302 16.7993C11.5202 16.8363 11.6182 16.8563 11.7172 16.8563C11.8172 16.8563 11.9152 16.8363 12.0052 16.7983C12.0942 16.7613 12.1752 16.7063 12.2432 16.6393L12.2452 16.6383L15.1072 13.7623C15.3972 13.4703 15.3972 12.9993 15.1052 12.7093Z'
        fill='white'
      />
    </svg>
  )
}
