import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toAbsoluteUrl } from 'src/app/utils/asset-helpers-utils'
import useProcessRequest from './InquiryFeedback.hook'

function InquiryFeedbackPage() {
  const location = useLocation()
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const tokenFromUrl = queryParams.get('token')
    setToken(tokenFromUrl)
  }, [location])

  const { message, isLoading, error } = useProcessRequest({ token })

  return (
    <div className='h-screen flex items-center justify-center'>
      <div className='rounded-xl bg-[#F9FAFB] p-6 flex flex-col items-center'>
        <img
          src={toAbsoluteUrl('/media/logos/bca-logo-default.svg')}
          className='w-[65%]'
          alt='BCAI-logo'
        />
        <div className='mt-4 text-center flex flex-col gap-1'>
          {isLoading ? (
            <h1 className='text-lg font-medium'>Loading...</h1>
          ) : (
            <>
              <h1 className='text-lg font-medium'>{message}</h1>
              {error && <p className='text-danger-400'>{error}</p>}
              <p className='text-[#344054] text-sm'>Kamu bisa menutup tab ini.</p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default InquiryFeedbackPage
