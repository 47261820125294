const PolicyManagementScreens = {
  POLICY_MANAGEMENT: {
    PATH: '/policy-management',
    TITLE: 'Policy Management',
    TITLE_ALT: 'Policy Management',
    PERMISSION: 'policy_management.read',
  },
  LIST_POLICY_MANAGEMENT: {
    PATH: '/policy-management/list',
    HEADER_NAME: 'Policy Management',
    TITLE: 'List Policy',
    TITLE_ALT: 'List Policy',
    PERMISSION: 'policy_management.read'
  },
  DETAIL_POLICY_MANAGEMENT: {
    PATH: '/policy-management/detail/:id',
    HEADER_NAME: 'Policy Management',
    TITLE: 'Detail Policy Management',
    TITLE_ALT: 'Detail Policy Management',
    PERMISSION: 'policy_management.read'
  },
}

export default PolicyManagementScreens
