const ProductManagementScreens = {
  PRODUCT_MANAGEMENT: {
    PATH: '/product-management',
    TITLE: 'Product Management',
    TITLE_ALT: 'Product Management',
    PERMISSION: 'product_management.read'
  },
  PRODUCT_MANAGEMENT_LIST: {
    PATH: '/product-management/list',
    HEADER_NAME: 'Product Management',
    TITLE: 'Product Management',
    TITLE_ALT: 'List Product',
    PERMISSION: 'product_management.read'
  },
  PRODUCT_MANAGEMENT_ADD: {
    PATH: '/product-management/create',
    HEADER_NAME: 'Product Management',
    TITLE: 'Create Product',
    TITLE_ALT: 'Create Product',
  },
  PRODUCT_MANAGEMENT_EDIT: {
    PATH: '/product-management/edit/:id',
    HEADER_NAME: 'Product Management',
    TITLE: 'Edit Product',
    TITLE_ALT: 'Edit Product',
    PERMISSION: 'product_management.create'
  },
  PRODUCT_MANAGEMENT_EDIT_OFFER: {
    PATH: '/product-management/edit/:id/offer',
    HEADER_NAME: 'Product Management',
    TITLE: 'Edit Product',
    TITLE_ALT: 'Edit Product',
    PERMISSION: 'product_management.create'
  },
  PRODUCT_MANAGEMENT_DETAIL: {
    PATH: '/product-management/detail/:id',
    HEADER_NAME: 'Product Management',
    TITLE: 'Product Detail',
    TITLE_ALT: 'Product Management',
    PERMISSION: 'product_management.read'
  },
  PRODUCT_MANAGEMENT_DETAIL_OFFER: {
    PATH: '/product-management/detail/:id/offer',
    HEADER_NAME: 'Product Management',
    TITLE: 'Product Detail',
    TITLE_ALT: 'Product Management',
    PERMISSION: 'product_management.read'
  },
  PRODUCT_OFFER_EDIT_POSITION: {
    PATH: '/product-management/edit-position',
    HEADER_NAME: 'Product Management',
    TITLE: 'List Product',
    TITLE_ALT: 'Product Management',
    PERMISSION: 'product_management.update'
  },
  PRODUCT_OFFER_SETUP_PRODUCT: {
    PATH: '/product-management/setup-product',
    HEADER_NAME: 'Product Management',
    TITLE: 'List Product',
    TITLE_ALT: 'Product Management',
    PERMISSION: 'product_management.update'
  },
  PRODUCT_TODAYS_OFFER: {
    PATH: '/product-management/offer',
    HEADER_NAME: 'Product Management',
    TITLE: 'Today\'s Offer',
    TITLE_ALT: 'Today\'s Offer',
    PERMISSION: 'product_management.read'
  },
}

export default ProductManagementScreens
