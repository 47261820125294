const CampaignScreens = {
  CAMPAIGN: {
    PATH: '/campaign',
    TITLE: 'Campaign',
    TITLE_ALT: 'Campaign',
  },
  LIST_POPUP_BANNER: {
    PATH: '/campaign/popup-banner',
    HEADER_NAME: 'Pop Up Banner',
    TITLE: 'Pop Up Banner',
    TITLE_ALT: 'List Pop Up',
    PERMISSION: 'manage_popup_banner.read'
  },
  ADD_POPUP_BANNER: {
    PATH: '/campaign/popup-banner/add',
    HEADER_NAME: 'Create Pop Up Banner',
    TITLE: 'Create Pop Up Banner',
    TITLE_ALT: 'Create Popup',
    PERMISSION: 'manage_popup_banner.create'
  },
  ADD_POPUP_BANNER_CONTENT: {
    PATH: '/campaign/popup-banner/add/:step',
    HEADER_NAME: 'Create Pop Up Banner',
    TITLE: 'Create Pop Up Banner',
    TITLE_ALT: 'Create Pop Up Banner',
    PERMISSION: 'manage_popup_banner.create'
  },
  EDIT_POPUP_BANNER_CONTENT: {
    PATH: '/campaign/popup-banner/edit/:id/:step',
    HEADER_NAME: 'Create Pop Up Banner',
    TITLE: 'Create Pop Up Banner',
    TITLE_ALT: 'Create Pop Up Banner',
    PERMISSION: 'manage_popup_banner.update'
  },
  EDIT_POPUP_BANNER: {
    PATH: '/campaign/popup-banner/edit/:id',
    TITLE: 'Edit Pop Up Banner',
    TITLE_ALT: 'Edit Pop Up',
    PERMISSION: 'manage_popup_banner.update'
  },
  DETAIL_POPUP_BANNER: {
    PATH: '/campaign/popup-banner/detail/:id',
    TITLE: 'Detail Pop Up Banner',
    TITLE_ALT: 'Detail Pop Up Banner',
    PERMISSION: 'manage_popup_banner.read'
  },
  LIST_MAIN_BANNER: {
    PATH: '/campaign/main-banner',
    HEADER_NAME: 'List Banner',
    TITLE: 'Main Banner',
    TITLE_ALT: 'List Banner',
    PERMISSION: 'manage_banner.read'
  },
  ADD_MAIN_BANNER: {
    PATH: '/campaign/main-banner/add',
    HEADER_NAME: 'Create Banner',
    TITLE: 'Create Banner',
    TITLE_ALT: 'Create Main Banner',
    PERMISSION: 'manage_banner.create'
  },
  DETAIL_MAIN_BANNER: {
    PATH: '/campaign/main-banner/detail/:id',
    HEADER_NAME: 'Detail Banner',
    TITLE: 'Detail Banner',
    TITLE_ALT: 'Detail Banner',
    PERMISSION: 'manage_banner.read'
  },
  EDIT_MAIN_BANNER: {
    PATH: '/campaign/main-banner/edit/:id',
    HEADER_NAME: 'Edit Banner',
    TITLE: 'Edit Banner',
    TITLE_ALT: 'Edit Banner',
    PERMISSION: 'manage_banner.update'
  },
  ADD_MAIN_BANNER_CONTENT: {
    PATH: '/campaign/main-banner/add/:bannerId/:step',
    HEADER_NAME: 'Create Main Banner',
    TITLE: 'Create Main Banner',
    TITLE_ALT: 'Create Main Banner',
    PERMISSION: 'manage_banner.create'
  },
  EDIT_MAIN_BANNER_CONTENT: {
    PATH: '/campaign/main-banner/edit/:id/:bannerId/:step',
    HEADER_NAME: 'Edit Main Banner',
    TITLE: 'Edit Main Banner',
    TITLE_ALT: 'Edit Main Banner',
    PERMISSION: 'manage_banner.update'
  },
  LIST_FEATURED_BANNER: {
    PATH: '/campaign/featured-banner',
    HEADER_NAME: 'Featured Banner',
    TITLE: 'Featured Banner',
    TITLE_ALT: 'Featured Banner',
    PERMISSION: 'manage_banner.read'
  },
  SETUP_FEATURED_BANNER: {
    PATH: '/campaign/featured-banner/setup',
    HEADER_NAME: 'Select Banner',
    TITLE: 'Select Banner',
    TITLE_ALT: 'Select Banner',
    PERMISSION: 'manage_banner.create'
  },
  EDIT_FEATURED_BANNER: {
    PATH: '/campaign/featured-banner/edit',
    HEADER_NAME: 'Featured Banner',
    TITLE: 'Featured Banner',
    TITLE_ALT: 'Featured Banner',
    PERMISSION: 'manage_banner.update'
  },
}

export default CampaignScreens
