import { useState, useEffect } from 'react'
import axios from 'axios'

interface UseProcessRequestProps {
  token: string | null
}

interface UseProcessRequestResult {
  message: string | null
  isLoading: boolean
  error: string | null
}

const useProcessRequest = ({ token }: UseProcessRequestProps): UseProcessRequestResult => {
  const [message, setMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const URL = `/api/v1/cms/inquiry-management/process-request/${token}`

  useEffect(() => {
    if (!token) return

    const processRequest = async () => {
      setIsLoading(true)
      setError(null)

      try {
        const response = await axios.post(
          URL,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        const responseMessage = response?.data?.response_schema?.response_message?.id || 'Request berhasil diproses!'
        setMessage(responseMessage)
      } catch (error: any) {
        const responseMessage = error?.response?.data?.response_schema?.response_message?.id || 'Terjadi kesalahan, silakan coba lagi nanti.'
        setError(responseMessage)
      } finally {
        setIsLoading(false)
      }
    }

    processRequest()
  }, [URL, token])

  return { message, isLoading, error }
}

export default useProcessRequest
