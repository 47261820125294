const MenuManagementScreens = {
  MENU_MANAGEMENT: {
    PATH: '/menu-management',
    TITLE: 'Menu Management',
    TITLE_ALT: 'Menu Management',
    PERMISSION: 'menu_management.read',
  },
  LIST_MENU_MANAGEMENT: {
    PATH: '/menu-management/list',
    HEADER_NAME: 'Menu Management',
    TITLE: 'List Menu Management',
    TITLE_ALT: 'List Menu Management',
    PERMISSION: 'menu_management.read'
  },
  EDIT_MENU_MANAGEMENT: {
    PATH: '/menu-management/edit/:id',
    HEADER_NAME: 'Menu Management',
    TITLE: 'Edit Menu',
    TITLE_ALT: 'Edit Menu',
    PERMISSION: 'menu_management.update'
  },
  DETAIL_MENU_MANAGEMENT: {
    PATH: '/menu-management/detail/:id',
    HEADER_NAME: 'Menu Management',
    TITLE: 'Detail Menu Management',
    TITLE_ALT: 'Detail Menu Management',
    PERMISSION: 'menu_management.read'
  },
}

export default MenuManagementScreens
