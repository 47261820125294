
export function hasText(str?: any): str is string {
  return !!str?.toString();
}

export function toCapitalize(str?: string | null) {
  if (!!str) {
    const strings = str.split(' ');
    const mapStrings = strings.map((item) => {
      const value = item.toLocaleLowerCase();
      const firstLetter = value.charAt(0).toUpperCase();
      return firstLetter + value.substring(1);
    });
    return mapStrings.join(' ');
  }
  return '';
}

export function reverseCoordinate(coordinate: string) {
  if (!coordinate || typeof coordinate !== 'string') {
    return null; // Mengembalikan null jika koordinat tidak valid
  }

  const parts = coordinate.split(',').map((part) => part.trim());
  
  if (parts.length !== 2 || isNaN(parseFloat(parts[0])) || isNaN(parseFloat(parts[1]))) {
    return null; // Mengembalikan null jika format tidak sesuai
  }

  return `${parts[1]} ${parts[0]}`; // Membalik menjadi long, lat
}

export function formatRupiah(value: string | number) {
  // Pastikan input berupa angka atau string
  if (typeof value !== 'number' && typeof value !== 'string') {
      throw new Error('Input harus berupa angka atau string');
  }

  // Konversi ke number jika input berupa string
  const number = typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;

  // Pastikan hasil konversi valid
  if (isNaN(number)) {
      throw new Error('Input tidak valid untuk dikonversi ke format Rupiah');
  }

  // Format ke Rupiah
  return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
  }).format(number);
}