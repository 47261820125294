import {SVGProps} from 'react'

export function ExportIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity='0.4'
        d='M6.447 22C3.996 22 2 19.9698 2 17.4755V12.5144C2 10.0252 3.99 8 6.437 8L17.553 8C20.005 8 22 10.0302 22 12.5256V17.4846C22 19.9748 20.01 22 17.563 22H16.623H6.447Z'
        fill='white'
      />
      <path
        d='M11.4548 2.22128L8.5458 5.06706C8.2458 5.36119 8.2458 5.83451 8.5478 6.12766C8.8498 6.41984 9.3368 6.41886 9.6368 6.12571L11.2298 4.56647V6.06144V14.4517C11.2298 14.8657 11.5748 15.2017 11.9998 15.2017C12.4258 15.2017 12.7698 14.8657 12.7698 14.4517V4.56647L14.3628 6.12571C14.6628 6.41886 15.1498 6.41984 15.4518 6.12766C15.6028 5.9806 15.6788 5.78874 15.6788 5.5959C15.6788 5.40501 15.6028 5.21315 15.4538 5.06706L12.5458 2.22128C12.4008 2.08006 12.2048 2.0002 11.9998 2.0002C11.7958 2.0002 11.5998 2.08006 11.4548 2.22128Z'
        fill='white'
      />
    </svg>
  )
}
